import { FIELDS, ROLE_FORM, ROLE_SUBMIT_BUTTON } from '../../constants/roles'
import _ from 'lodash'
import {
  isCaptchaField,
  isRadioGroup,
  isUploadButton,
  replaceMessageInnerText,
  toMiliseconds,
  getBaseUrl,
} from '../viewer-utils'
import { post } from '../services/fetch-utils'
import { FormsFieldPreset, SecondsToResetDefaults, SuccessActionTypes } from '@wix/forms-common'
import { EmailConfig, Field, SubmitFormRequest, SubmitFormResponse } from '../../types/domain-types'
import { createFieldDto, getFieldValue } from '../field-dto/field-dto'
import { isNotEmptyEmailId } from '../../utils/utils'
import { submitUtils } from '../submit-utils'
import { siteStore } from '../stores/site-store'

// TODO: Cover this file (registration form) with tests

const getRecipients = (emailIds: string[]) => {
  const sendToOwner: boolean = _.isEmpty(emailIds[0])
  const actualEmailIds: string[] = emailIds.filter(isNotEmptyEmailId)

  return { sendToOwner, emailIds: actualEmailIds }
}

const createEmailConfig = ({
  emailIds,
  selectedSiteUsersIds,
  inboxOptOut,
}: {
  emailIds: string[]
  selectedSiteUsersIds?: string[]
  inboxOptOut?: boolean
}): EmailConfig => {
  const recipients = getRecipients(emailIds)

  if (!_.isBoolean(inboxOptOut) || inboxOptOut) {
    if (recipients.sendToOwner) {
      return {
        sendToOwnerAndEmails: {
          emailIds: [...recipients.emailIds],
        },
      }
    }

    return {
      sendToEmails: {
        emailIds: [...recipients.emailIds],
      },
    }
  } else {
    if (!selectedSiteUsersIds) {
      if (recipients.sendToOwner) {
        return {
          sendToOwner: {},
        }
      }
    }

    return {
      sendToContributors: {
        userIds: selectedSiteUsersIds || [],
      },
    }
  }
}

const FILTERED_FIELDS = [FormsFieldPreset.GENERAL_RECAPTCHA]

const createFieldsDto = ({ fields, attachments, options }) => {
  const fieldsDto = []

  const validFields = _.filter(
    fields,
    field => !_.includes(FILTERED_FIELDS, _.get(field, 'connectionConfig.fieldType')),
  )

  _.forEach(validFields, field => {
    const fieldDto: Field = createFieldDto({ field, attachments, options })
    fieldsDto.push(fieldDto)
  })

  return fieldsDto
}

const enrichPayloadWithCaptcha = ({ $w, payload }) => {
  const captchaField = $w(`@${FIELDS.ROLE_FIELD_RECAPTCHA}`)

  if (captchaField.length > 0) {
    const value = getFieldValue(captchaField)
    payload.security = { captcha: value }
  }
}

const sendActivity = async ($w, { attachments, fields, wixWindow, formId }) => {
  siteStore.interactionStarted('submission')

  const form = $w(`@${ROLE_FORM}`)
  const {
    emailId,
    secondEmailId,
    emailIds,
    labels,
    formName = '',
    selectedSiteUsersIds,
    inboxOptOut,
    doubleOptIn,
  } = form.connectionConfig

  const fieldsDto: Field[] = createFieldsDto({ fields, attachments, options: { doubleOptIn } })
  const emailConfig: EmailConfig = createEmailConfig({
    emailIds: emailIds || [emailId, secondEmailId],
    selectedSiteUsersIds,
    inboxOptOut,
  })

  const payload: SubmitFormRequest = {
    formProperties: {
      formName,
      formId,
    },
    emailConfig,
    viewMode: wixWindow.viewMode,
    fields: fieldsDto,
    labelIds: _.compact(labels),
  }

  enrichPayloadWithCaptcha({ $w, payload })

  const shouldDisableRetry = _.has(payload, 'security')
  const baseUrl = getBaseUrl()

  const response = await post<SubmitFormResponse>(
    baseUrl,
    '_api/wix-forms/v1/submit-form',
    payload,
    shouldDisableRetry,
  )

  siteStore.interactionEnded('submission')

  return response
}

const navigateToNextStep = $w => {
  const $multiStepForm = $w(`@${ROLE_FORM}`)
  return submitUtils.navigateToStepByOffset($multiStepForm, 1)
}

const showSuccessMessageIfExists = ({
  $message,
  secondsToResetForm,
  successActionType,
  successLinkValue,
  wixLocation,
  $w,
  isMultiStepForm,
}) => {
  switch (successActionType) {
    case SuccessActionTypes.LINK:
    case SuccessActionTypes.EXTERNAL_LINK:
      setTimeout(() => wixLocation.to(siteStore.platformApi.links.toUrl(successLinkValue)), 100)
      return Promise.resolve()

    case SuccessActionTypes.DOWNLOAD_DOCUMENT:
      if (_.get($message, 'html', undefined) === undefined) {
        return Promise.resolve()
      }
      replaceMessageInnerText(
        $message,
        innerText =>
          `<a href="${siteStore.platformApi.links.toUrl(
            successLinkValue,
          )}" target="_blank" role="alert">${innerText}</a>`,
      )
      $message.show()
      return isMultiStepForm ? navigateToNextStep($w) : Promise.resolve()

    default:
      const hasMessageContent = _.get($message, 'html', undefined) !== undefined
      if (hasMessageContent) {
        replaceMessageInnerText($message, innerText => `<span role="alert">${innerText}</span>`)
        $message.show()
      }

      if (isMultiStepForm) {
        return navigateToNextStep($w)
      }

      return hasMessageContent &&
        secondsToResetForm >= SecondsToResetDefaults.MIN &&
        secondsToResetForm <= SecondsToResetDefaults.MAX
        ? new Promise(resolve =>
            setTimeout(() => resolve($message.hide()), toMiliseconds(secondsToResetForm)),
          )
        : Promise.resolve()
  }
}

export class FormStrategy {
  constructor(protected submitArgs) {}

  static isEnabled($w) {
    return $w(`@${ROLE_SUBMIT_BUTTON}`)[0]
  }

  registerCustomValidation(submitArgs) {
    const { isNumberCustomBehaviorEnabled } = submitArgs

    if (isNumberCustomBehaviorEnabled) {
      this.registerNumberInputValidation(submitArgs)
    }
  }

  registerNumberInputValidation({ $w }) {
    const fields = $w(`@${FIELDS.ROLE_FIELD_TEXT}`)
    const numbers = _.filter(fields, field => _.get(field, 'inputType') == 'number')

    _.forEach(numbers, number => {
      if (number.onBlur) {
        number.onBlur(e => {
          number.value = e.target.value
        })
      }
    })
  }

  validateFields(fields: any) {
    const fieldsToTestValidity = _.filter(
      fields,
      field => !isUploadButton(field) || (field.required && field.value.length === 0),
    )

    return _.reject(fieldsToTestValidity, field => {
      if (isRadioGroup(field)) {
        // TODO - waiting for full fix for radioGroup
        return !field.required || field.value.length > 0
      }

      if (isCaptchaField(field)) {
        return !_.isEmpty(field.token)
      }

      if ('valid' in field) {
        return field.valid
      }

      return true
    })
  }

  async execute({
    attachments,
    fields,
    skipSendActivity = false,
    formId,
  }): Promise<SubmitFormResponse> {
    if (skipSendActivity) return Promise.resolve({})

    const { $w, wixWindow } = this.submitArgs

    return sendActivity($w, {
      attachments,
      fields,
      wixWindow,
      formId,
    })
  }

  async postSubmission() {
    const {
      $message,
      secondsToResetForm,
      successActionType,
      successLinkValue,
      wixLocation,
      isMultiStepForm,
      $w,
    } = this.submitArgs
    await showSuccessMessageIfExists({
      $w,
      isMultiStepForm,
      $message,
      secondsToResetForm,
      successActionType,
      successLinkValue,
      wixLocation,
    })
  }
}
